import IconCustomerExp from "@assets/career-tracks/customer-exp.svg"
import IconEnterpriseExcellence from "@assets/career-tracks/enterprise-excellence.svg"
import IconExecutionEnablement from "@assets/career-tracks/execution-enablement.svg"
import IconStrategyPolicyAdvisory from "@assets/career-tracks/strategy-policy-advisory.svg"

// path svgs
import EAC_00 from "@assets/specializations/engagement-and-collaborations/map00.inline.svg"
import EAC_01 from "@assets/specializations/engagement-and-collaborations/map01.inline.svg"
import EAC_02 from "@assets/specializations/engagement-and-collaborations/map02.inline.svg"
import EAC_03 from "@assets/specializations/engagement-and-collaborations/map03.inline.svg"
import EAC_04 from "@assets/specializations/engagement-and-collaborations/map04.inline.svg"

import RCAD_00 from "@assets/specializations/regional-collaboration-and-development/map00.inline.svg"
import RCAD_01 from "@assets/specializations/regional-collaboration-and-development/map01.inline.svg"
import RCAD_02 from "@assets/specializations/regional-collaboration-and-development/map02.inline.svg"
import RCAD_03 from "@assets/specializations/regional-collaboration-and-development/map03.inline.svg"
import RCAD_04 from "@assets/specializations/regional-collaboration-and-development/map04.inline.svg"

import SDAD_00 from "@assets/specializations/services-design-and-delivery/map00.inline.svg"
import SDAD_01 from "@assets/specializations/services-design-and-delivery/map01.inline.svg"
import SDAD_02 from "@assets/specializations/services-design-and-delivery/map02.inline.svg"
import SDAD_03 from "@assets/specializations/services-design-and-delivery/map03.inline.svg"
import SDAD_04 from "@assets/specializations/services-design-and-delivery/map04.inline.svg"

import DAT_00 from "@assets/specializations/data-and-technology/map00.inline.svg"
import DAT_01 from "@assets/specializations/data-and-technology/map01.inline.svg"
import DAT_02 from "@assets/specializations/data-and-technology/map02.inline.svg"
import DAT_03 from "@assets/specializations/data-and-technology/map03.inline.svg"
import DAT_04 from "@assets/specializations/data-and-technology/map04.inline.svg"
import DAT_05 from "@assets/specializations/data-and-technology/map05.inline.svg"

import FINANCE_00 from "@assets/specializations/finance/map00.inline.svg"
import FINANCE_01 from "@assets/specializations/finance/map01.inline.svg"
import FINANCE_02 from "@assets/specializations/finance/map02.inline.svg"
import FINANCE_03 from "@assets/specializations/finance/map03.inline.svg"
import FINANCE_04 from "@assets/specializations/finance/map04.inline.svg"
import FINANCE_05 from "@assets/specializations/finance/map05.inline.svg"

import LEGAL_00 from "@assets/specializations/legal/map00.inline.svg"
import LEGAL_01 from "@assets/specializations/legal/map01.inline.svg"
import LEGAL_02 from "@assets/specializations/legal/map02.inline.svg"
import LEGAL_03 from "@assets/specializations/legal/map03.inline.svg"
import LEGAL_04 from "@assets/specializations/legal/map04.inline.svg"

import OE_00 from "@assets/specializations/organization-excellence/map00.inline.svg"
import OE_01 from "@assets/specializations/organization-excellence/map01.inline.svg"
import OE_02 from "@assets/specializations/organization-excellence/map02.inline.svg"
import OE_03 from "@assets/specializations/organization-excellence/map03.inline.svg"
import OE_04 from "@assets/specializations/organization-excellence/map04.inline.svg"

import FM_00 from "@assets/specializations/facilities-management/map00.inline.svg"
import FM_01 from "@assets/specializations/facilities-management/map01.inline.svg"
import FM_02 from "@assets/specializations/facilities-management/map02.inline.svg"
import FM_03 from "@assets/specializations/facilities-management/map03.inline.svg"
import FM_04 from "@assets/specializations/facilities-management/map04.inline.svg"
import FM_05 from "@assets/specializations/facilities-management/map05.inline.svg"

import PAPM_00 from "@assets/specializations/project-and-performance-management/map00.inline.svg"
import PAPM_01 from "@assets/specializations/project-and-performance-management/map01.inline.svg"
import PAPM_02 from "@assets/specializations/project-and-performance-management/map02.inline.svg"
import PAPM_03 from "@assets/specializations/project-and-performance-management/map03.inline.svg"
import PAPM_04 from "@assets/specializations/project-and-performance-management/map04.inline.svg"

import PAIA_00 from "@assets/specializations/protocol-and-international-affairs/map00.inline.svg"
import PAIA_01 from "@assets/specializations/protocol-and-international-affairs/map01.inline.svg"
import PAIA_02 from "@assets/specializations/protocol-and-international-affairs/map02.inline.svg"
import PAIA_03 from "@assets/specializations/protocol-and-international-affairs/map03.inline.svg"
import PAIA_04 from "@assets/specializations/protocol-and-international-affairs/map04.inline.svg"
import PAIA_05 from "@assets/specializations/protocol-and-international-affairs/map05.inline.svg"

import SAA_00 from "@assets/specializations/secretariat-and-administration/map00.inline.svg"
import SAA_01 from "@assets/specializations/secretariat-and-administration/map01.inline.svg"
import SAA_02 from "@assets/specializations/secretariat-and-administration/map02.inline.svg"
import SAA_03 from "@assets/specializations/secretariat-and-administration/map03.inline.svg"
import SAA_05 from "@assets/specializations/secretariat-and-administration/map05.inline.svg"

import ISE_00 from "@assets/specializations/industry-specific-expertise/map00.inline.svg"
import ISE_01 from "@assets/specializations/industry-specific-expertise/map01.inline.svg"
import ISE_02 from "@assets/specializations/industry-specific-expertise/map02.inline.svg"
import ISE_03 from "@assets/specializations/industry-specific-expertise/map03.inline.svg"
import ISE_04 from "@assets/specializations/industry-specific-expertise/map04.inline.svg"
import ISE_05 from "@assets/specializations/industry-specific-expertise/map05.inline.svg"

import PDAE_00 from "@assets/specializations/policy-development-and-evaluation/map00.inline.svg"
import PDAE_01 from "@assets/specializations/policy-development-and-evaluation/map01.inline.svg"
import PDAE_02 from "@assets/specializations/policy-development-and-evaluation/map02.inline.svg"
import PDAE_03 from "@assets/specializations/policy-development-and-evaluation/map03.inline.svg"
import PDAE_04 from "@assets/specializations/policy-development-and-evaluation/map04.inline.svg"

import RAA_00 from "@assets/specializations/research-and-analysis/map00.inline.svg"
import RAA_01 from "@assets/specializations/research-and-analysis/map01.inline.svg"
import RAA_02 from "@assets/specializations/research-and-analysis/map02.inline.svg"
import RAA_03 from "@assets/specializations/research-and-analysis/map03.inline.svg"
import RAA_04 from "@assets/specializations/research-and-analysis/map04.inline.svg"

import STRATEGY_00 from "@assets/specializations/strategy/map00.inline.svg"
import STRATEGY_01 from "@assets/specializations/strategy/map01.inline.svg"
import STRATEGY_02 from "@assets/specializations/strategy/map02.inline.svg"
import STRATEGY_03 from "@assets/specializations/strategy/map03.inline.svg"
import STRATEGY_04 from "@assets/specializations/strategy/map04.inline.svg"

// PDFs
import PDF_ENGAGEMENT_COLLABORATIONS from "@assets/pdf/engagement-collaborations.pdf"
import PDF_REGIONAL_COLLABORATION_DEVELOPMENT from "@assets/pdf/regional-collaboration-development.pdf"
import PDF_SERVICES_DESIGN_DELIVERY from "@assets/pdf/services-design-delivery.pdf"
import PDF_DATA_TECHNOLOGY from "@assets/pdf/data-technology.pdf"
import PDF_FINANCE from "@assets/pdf/finance.pdf"
import PDF_LEGAL from "@assets/pdf/legal.pdf"
import PDF_ORGANIZATION_EXCELLENCE from "@assets/pdf/organization-excellence.pdf"
import PDF_FACILITIES_MANAGEMENT from "@assets/pdf/facilities-management.pdf"
import PDF_PROJECT_PERFORMANCE_MANAGEMENT from "@assets/pdf/project-performance-management.pdf"
import PDF_PROTOCOL_INTERNATIONAL_AFFAIRS from "@assets/pdf/protocol-international-affairs.pdf"
import PDF_SECRETARIAT_ADMINISTRATION from "@assets/pdf/secretariat-administration.pdf"
import PDF_INDUSTRY_SPECIFIC_EXPERTISE from "@assets/pdf/industry-specific-expertise.pdf"
import PDF_POLICY_DEVELOPMENT_AND_EVALUATION from "@assets/pdf/policy-development-evaluation.pdf"
import PDF_RESEARCH_ANALYSIS from "@assets/pdf/research-analysis.pdf"
import PDF_STRATEGY from "@assets/pdf/strategy.pdf"

const MENU = require("./menu.js")

MENU.FIRST_LEVEL_MENU[0].icon = IconCustomerExp
MENU.FIRST_LEVEL_MENU[1].icon = IconEnterpriseExcellence
MENU.FIRST_LEVEL_MENU[2].icon = IconExecutionEnablement
MENU.FIRST_LEVEL_MENU[3].icon = IconStrategyPolicyAdvisory

const SVG_MAPPING = {
  "engagement-and-collaborations": [EAC_00, EAC_01, EAC_02, EAC_03, EAC_04],
  "regional-collaboration-and-development": [
    RCAD_00,
    RCAD_01,
    RCAD_02,
    RCAD_03,
    RCAD_04,
  ],
  "services-design-and-delivery": [SDAD_00, SDAD_01, SDAD_02, SDAD_03, SDAD_04],
  "data-and-technology": [DAT_00, DAT_01, DAT_02, DAT_03, DAT_04, DAT_05],
  finance: [
    FINANCE_00,
    FINANCE_01,
    FINANCE_02,
    FINANCE_03,
    FINANCE_04,
    FINANCE_05,
  ],
  legal: [LEGAL_00, LEGAL_01, LEGAL_02, LEGAL_03, LEGAL_04],
  "organization-excellence": [OE_00, OE_01, OE_02, OE_03, OE_04],
  "facilities-management": [FM_00, FM_01, FM_02, FM_03, FM_04, FM_05],
  "project-and-performance-management": [
    PAPM_00,
    PAPM_01,
    PAPM_02,
    PAPM_03,
    PAPM_04,
  ],
  "protocol-and-international-affairs": [
    PAIA_00,
    PAIA_01,
    PAIA_02,
    PAIA_03,
    PAIA_04,
    PAIA_05,
  ],
  "secretariat-and-administration": [SAA_00, SAA_01, SAA_02, SAA_03, SAA_05],
  "industry-specific-expertise": [
    ISE_00,
    ISE_01,
    ISE_02,
    ISE_03,
    ISE_04,
    ISE_05,
  ],
  "policy-development-and-evaluation": [
    PDAE_00,
    PDAE_01,
    PDAE_02,
    PDAE_03,
    PDAE_04,
  ],
  "research-and-analysis": [RAA_00, RAA_01, RAA_02, RAA_03, RAA_04],
  strategy: [STRATEGY_00, STRATEGY_01, STRATEGY_02, STRATEGY_03, STRATEGY_04],
}

const PDF_MAPPING = {
  "engagement-and-collaborations": PDF_ENGAGEMENT_COLLABORATIONS,
  "regional-collaboration-and-development": PDF_REGIONAL_COLLABORATION_DEVELOPMENT,
  "services-design-and-delivery": PDF_SERVICES_DESIGN_DELIVERY,
  "data-and-technology": PDF_DATA_TECHNOLOGY,
  finance: PDF_FINANCE,
  legal: PDF_LEGAL,
  "organization-excellence": PDF_ORGANIZATION_EXCELLENCE,
  "facilities-management": PDF_FACILITIES_MANAGEMENT,
  "project-and-performance-management": PDF_PROJECT_PERFORMANCE_MANAGEMENT,
  "protocol-and-international-affairs": PDF_PROTOCOL_INTERNATIONAL_AFFAIRS,
  "secretariat-and-administration": PDF_SECRETARIAT_ADMINISTRATION,
  "industry-specific-expertise": PDF_INDUSTRY_SPECIFIC_EXPERTISE,
  "policy-development-and-evaluation": PDF_POLICY_DEVELOPMENT_AND_EVALUATION,
  "research-and-analysis": PDF_RESEARCH_ANALYSIS,
  strategy: PDF_STRATEGY,
}

MENU.FIRST_LEVEL_MENU = MENU.FIRST_LEVEL_MENU.map(flm => {
  flm.specializations = flm.specializations.map(sp => {
    return Object.assign({}, sp, {
      svgs: SVG_MAPPING[sp.slug],
      pdf: PDF_MAPPING[sp.slug],
    })
  })

  return flm
})

export default MENU.FIRST_LEVEL_MENU
